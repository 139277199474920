<template>
  <div class="testSvg">
    <div id="svg"></div>
  </div>
</template>

<script>
import svgContent from "./js/svg";
export default {
  name: "TestSvg",
  data() {
    return {}
  },
  mounted() {
    document.getElementById("svg").innerHTML = svgContent;
  },
}
</script>

<style scoped>
.testSvg {
  width: 100%;
  height: 100%;
}
#svg {
  width: 1344px; 
  height: 803px;
  margin: 0 auto;
}
</style>